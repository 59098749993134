.right_main_bx {
    background-color: red;
}

.rightSide_ds_box {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rightSide_ds_box {
    background: radial-gradient(50% 50% at 50% 50%, #9B7AFF 0%, #170B3B 100%);
    backdrop-filter: blur(2.5px);
}

.rightSide_ds_box form {
    border-radius: 20px;
    background: rgba(252, 250, 250, 0.55);
    max-width: 450px;
    margin: auto;
    padding: 30px;
    width: 100%;
}

/* =================  create flex for input =================  */
.create_user {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    column-gap: 15px;
}

.selectRole_main {
    max-width: 32%;
    width: 100%;
    box-sizing: border-box;
}

.create_user>div {
    max-width: 32%;
    width: 100%;
    box-sizing: border-box;
}

/* ========================== Profile modal css code ======================= */
.profile_modal {
    display: flex;
}

.right_Profile_bx {
    width: 200px;
    max-width: 100%;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    max-width: 862px !important;
}

.right_Profile_bx {
    background: linear-gradient(174deg, rgba(23, 11, 59, 1) 0%, rgba(115, 89, 196, 1) 80%, rgba(115, 89, 196, 1) 96%, rgba(115, 89, 196, 1) 100%);
    padding: 0px 20px;
}

.profile_inner_rtyui {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.table_size_sdfsd {
    width: 664px;
}

.css-ypiqx9-MuiDialogContent-root {
    padding: 0 !important;
}

.close_btn_vi {
    display: flex;
    justify-content: end;
    margin-bottom: 50px;
    margin-top: 20px;
}

.profile_inner_rtyui .profile_img_br {
    width: 100px;
    height: 100px;
}

.profile_inner_rtyui .profile_img_br img {
    height: 100%;
    widows: 100%;
    border-radius: 100px;
    border: 3px solid #6C6971;
}

.profile_inner_rtyui h1 {
    color: #FCFAFA;
    text-align: center;
    font-family: "Source Sans Pro";
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.profile_inner_rtyui .thre_boxes_bd .view_pr_box {
    display: flex;
    width: 80px;
    height: 80px;
    padding: 9px 11px;
    flex-direction: column;
    margin-bottom: 20px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.71);
    backdrop-filter: blur(2.5px);
}

.disabaled_bx {
    padding: 30px 15px;
}
.disabaled_bx .close_btn_vi_cg {
    display: flex;
    justify-content: end;
}
.disabaled_bx .close_btn_vi_cg svg {
    width: 33.231px;
    height: 32px;
    flex-shrink: 0;
    color: #000;
    cursor: pointer;
}

.disabaled_bx .emogi_image {
    width: 100px;
    height: 100px;
    margin: auto;
}

.disabaled_bx .emogi_image img {
    width: 100%;
    height: auto;
}

.disabaled_title h1 {
    color: var(--Primary, #170B3B);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 30px;
}

.disabaled_title p {
    color: rgba(23, 11, 59, 0.30);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
}

/* ========================== Profile modal css code ======================= */

@media screen and (max-width: 767px) {
    .create_user input {
        width: 49%;
    }
}

@media screen and (max-width: 480px) {
    .create_user input {
        width: 100%;
    }
}

/* =================  create flex for input =================  */