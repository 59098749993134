.card_{
    height: 200px;
    border-radius: 5px;
}
.stat{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}
.stat-value, .stat-title{
    display: flex;
    justify-content: center;
}
.stat-value{
    margin-top: 10px;
}

.stat-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 8px;
}
.stat-btn1{
    display: flex;
    width: 280px;
    height: 34px;
    padding: 0px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid var(--Primary, #170B3B);
    font-weight: 700;
}
.card_par{
    display: flex;
    width: 100% !important;
    gap: 10px;
}
.cardr2{
    display: flex;
    width: 470px;
    height: fit-content;
    padding: 20px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-radius: 8px;
    background: var(--White-Text, #FCFAFA);
    box-shadow: 0px 2px 5px 0px rgba(155, 122, 255, 0.25);
}
.cardr2_{
    display: flex;
    width: 470px;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
}
.cardr2 h4{
    font-weight: 700;
}
.cardr2_ .hr{
    height: 1.5px;
    width: 90%;
    background-color: aqua;
}
.tickets_ .hr{
    height: 1.5px;
    width: 100%;
    background-color: aqua;
}
.card_img{
     display: flex;
     gap: 10px;
}
.card_img_{
    display: flex;
    width: 90px;
    height: 90px;
    padding: 15px 9px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: #FCFAFA;
    box-shadow: 0px 2px 5px 0px rgba(155, 122, 255, 0.25);
    border-radius: 4px;
}
.card_img_ img{
    width: 60px !important;
    height: 60px;
    flex-shrink: 0;
} 
.card_img_ card_img1_{
    width: 70.588px;
    height: 60px;
    flex-shrink: 0;
}
.card_img_ card_img2_{
    width: 60px;
    height: 60px;
    flex-shrink: 0;
 }
 .tickets_{
    display: flex;
    padding: 0 0 0 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-left: 5px solid #2ED5EC;
    /* background: var(--White-Text, #FCFAFA);
    box-shadow: 0px 0px 5px 0px rgba(155, 122, 255, 0.25); */
 }
 .ticket_nav{
    display: flex;
    justify-content: space-between;
 }
 .ticket_nav ul{
    list-style-type: none;
    display: flex;
    align-items: center;
    gap: 274px;
 }
 .ticket_nav1{
    font-weight: 700;
 }
 .notif {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    width: 80%;
 }
 .notif ul{
    display: flex;
    gap: 15px !important;
 }
 .notif h2{
    font-weight: 600;
 }
 .Assign_{
     display: flex;
     justify-content: space-between;
     margin-top: 10px;
     width: 90%;
 }
 .Assign_ button{
    display: flex;
    padding: 2px 16px;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: #2ED5EC;
    color: white;
 }
 .para_{
    margin-top: 10px;
 }
 .rightsidebar_par{
    display: flex;
    justify-content: start;
    margin-top: 31px;
    width: 100%;
 }
 
 .rightsidebar_ .head{
    display: flex;
    justify-content: flex-start;
    margin-left: 26px;
    gap: 20px;
 }
 .rightsidebar_ .button1_{
    font-weight: 500;
 }
 .rightsidebar_ .button2_{
    display: flex;
    height: 25px;
    padding: 2px 16px;
    border-radius: 8px;
    background: #EDEDED;
    align-items: center;
    font-weight: 500;
 }
 .content_{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 25px 0 25px 25px;
    
 }
 .content_ p{
    font-size: 15px;
 }

 /* Profile Setting Css */
.information_{
    width: 100%;
    height: auto;
    margin: auto;
    margin-top: 30px;
}
.info_btn{
    padding-right: 24px;
    padding-left: 24px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 8px;
    border: 2px solid black;
    font-weight: 700;
    font-size: 20px;
}
.info_btn_delete{
    padding-right: 24px;
    padding-left: 24px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-weight: 700;
    font-size: 20px;
    border-radius: 4px;
}
/* Generative Invoice Css */

.invoice_btn1{
    background-color: #170b3b;
    color: white;
    padding-right: 36px;
    padding-left: 36px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 8px;
    border: 1px solid black;
    font-weight: 700;
    font-size: 17px;
}
.invoice_btn2{
    padding-right: 36px;
    padding-left: 36px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 8px;
    border: 1px solid black;
    font-weight: 700;
    font-size: 17px;
}