.drawer-side .menu{
    background-color: #170b3b !important;
    color: white !important;
}
.drawer-side{
    width: 250px !important;
}
.drawer-side ul{
    width: auto !important;
    gap: 12px !important;
    padding-right: 8px;   
}
::-webkit-scrollbar {
    display: none;
}
.drawer-side ul li a[aria-current="page"] {
        background-color: #9b7aff !important;
        --tw-bg-opacity: 1;
        /* border-radius: 0 !important; */
}
.drawer-side ul li a:hover {
    background-color: #9b7aff !important;
    --tw-bg-opacity: 1;
}
.menu :where(li:not(.menu-title) > *:not(ul):not(details):not(.menu-title)), .menu :where(li:not(.menu-title) > details > summary:not(.menu-title)) {
    border-radius: 0 !important;
  
}  
.drawer-side ul div{
    line-height: 60px !important;
    margin-bottom: 35px !important;
    padding-top: 10px;
    /* position: fixed;
    top: 0; */
}
.drawer-side ul div a{
    display: flex;
  justify-content: center;
}
.drawer-side ul li a span {
    color: #fff !important;
    font-weight: 700;
    font-size: 15px;
}
.main_page{
    padding: 0 !important;
}
.form_main{
    background-color: #f4f2fd;
    padding-left: 60px;
    padding-right: 60px;
}
::-webkit-input-placeholder{
    color: #e4e2e2 !important; 
  }

  /* .w-full{
    width: 85%;
  } */
  .heading_product{
    color: #353434;
    padding-top: 96px;
    padding-bottom: 12px;
    
  }
  .upload_product{
    padding-bottom: 112px;
    margin: auto;
  }
  .upload_product button{
    border-radius: 10px;
    padding: 6px 50px;
    font-size: 16px;
}
  .textarea_height{
    height: 200px;
  }
  .title_card{
    width: 100% !important;
    background-color: #F3F2F8 !important;
  }
  .title_card1{
    width: 100% !important;
    background-color: #F3F2F8 !important;
  }
  .card{
    border-radius: 0;
    margin-top: 0;
  }

  /* thead{
    line-height: 1.5rem !important;
    background-color: #170B3B !important;
  } */
  .bg-cyan600{
    background-color: #170B3B;
    color: white;
    font-weight: 500;
    border-radius: 0;
  }
  /* .rounded-l-xl .rounded-r-xl{
    border-radius: 0 !important;
    
  } */
  tbody tr{
    background-color: #E4E2EB;
    color: #353434;
    line-height: 0.75rem;
  }
  tr:nth-child(even){
    background-color: #DDDBE5;
  }
  .dark:text-green800 .dark:text-white{
    color: #353434 !important;
  }
  .table :where(thead, tbody) :where(tr:not(:last-child)){
    border-bottom-width: 2px;
    color: white;
  }
  .table-btn{
    padding: 6px 10px;
  }
  .order_line{
    line-height: 18px !important;
  }